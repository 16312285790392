import React from 'react'
import { Container } from 'react-bootstrap'

const Cta = () => {
  return (
    <section id='ctaLand' className='tc land flex jcc aic h-100-v'>
      <Container>
        <h2 className="c-white title-intro-section c-pink">Workouts and Exercises</h2>
        <div className="s-40"></div>
        <p className="c-white title-intro-sub w-80 ma">Talk about this product's most important feature or service. Highlighting the most popular one works, too! Talk about this product's most important feature or service. Highlighting the most popular one works, too!</p>
      </Container>
    </section>
  )
}

export { Cta }
