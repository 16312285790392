import React from 'react'
import { Container } from 'react-bootstrap'

const Contest = () => {
  return (
    <section className='flex jcc aic h-100-v'>
      <Container>
        <h2 className="c-white title-intro-section c-pink tc">Reviews</h2>
        <div className="s-50"></div>
        <div className="row">
          <div className="col-lg-4">
            <div className="card-white">
              <p className="c-white title-intro-sub-hc w-80 ma mt-3">Testimonials are short quotes from people who love your brand. It's a great way to convince customers to try your services.</p>
              <p className="c-pink title-intro-sub-hc w-80 ma"><strong>Frankie,<br />Virtual Assistant</strong></p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card-white">
              <p className="c-white title-intro-sub-hc w-80 ma mt-3">Testimonials are short quotes from people who love your brand. It's a great way to convince customers to try your services.</p>
              <p className="c-pink title-intro-sub-hc w-80 ma"><strong>Devin,<br />Business Analyst</strong></p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card-white">
              <p className="c-white title-intro-sub-hc w-80 ma mt-3">Testimonials are short quotes from people who love your brand. It's a great way to convince customers to try your services.</p>
              <p className="c-pink title-intro-sub-hc w-80 ma"><strong>Brooke,<br />Marketing Specialist</strong></p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}

export { Contest }
