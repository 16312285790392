import React from 'react'
import { Container } from 'react-bootstrap'
import { Button } from '../elements/Button'

const Jury = () => {
  return (
    <section id='juryLand' className='tc land flex jcc aic h-100-v'>
      <Container>
        <h2 className="c-white title-intro-section c-pink">Join to Jury</h2>
        <div className="s-40"></div>
        <p className="c-white title-intro-sub w-80 ma">Talk about this product's most important feature or service. Highlighting the most popular one works, too! Talk about this product's most important feature or service. Highlighting the most popular one works, too!</p>
        <div className="s-40"></div>
        <Button color='pink' text='Become Jury' round='circle' />
      </Container>
    </section>
  )
}

export { Jury }
