import React from 'react'
import { Container } from 'react-bootstrap'
import facebook from '../../assets/hackaton/facebook.svg'
import instagram from '../../assets/hackaton/instagram.svg'
import twitter from '../../assets/hackaton/twitter.svg'

const Contact = () => {
  return (
    <section id='contactLand' className='flex jcc aic h-100-v'>
      <Container>
        <div className="w-80 ma">
          <h2 className="c-white title-intro-section c-pink">Contact Us</h2>
          <div className="s-40"></div>
          <p className='title-section-sub flex jcfs aic'>
            <span className="w-10 c-pink"><strong>PHONE:</strong></span>
            <span className="c-white">(123) 456-7890</span>
          </p>
          <div className="s-5"></div>
          <p className='title-section-sub flex jcfs aic'>
            <span className="w-10 c-pink"><strong>EMAIL:</strong></span>
            <span className="c-white">hello@reallygreatsite.com</span>
          </p>
          <div className="s-5"></div>
          <p className='title-section-sub flex jcfs aic'>
            <span className="w-10 c-pink"><strong>SOCIAL:</strong></span>
            <span>
              <a href="/"><img src={facebook} alt="facebook" /></a>
              <a href="/" className='mx-3'><img src={instagram} alt="instagram" /></a>
              <a href="/" className='mx-3'><img src={twitter} alt="twitter" /></a>
            </span>
          </p>
        </div>
        <div className="s-40"></div>
      </Container>
    </section>
  )
}

export { Contact }
