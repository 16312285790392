import React from 'react'
import { Container } from 'react-bootstrap'
import { Button } from '../elements/Button'
import logo from '../../LOGO_PINK.svg'

const Header = () => {
  return (
    <>
      <header className='w-100 flex aic jcc h-128'>
        <Container>
          <div className="flex aic jcsb">
            <h1 className="site-title">
              <a href='/' aria-label='CraftFlow.Art Logo'>
                <img src={logo} width={270} height={67} alt='CraftFlow.Art' />
              </a>
            </h1>
            <Button color='pink' round='circle outline' text='Join Now' />
          </div>
        </Container>
      </header>
    </>
  )
}

export { Header }
