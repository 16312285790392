import React from 'react'
import { Button } from '../elements/Button'
import pic from '../../assets/hackaton/hero.png'

const Intro = () => {
  return (
    <div id='hero' className='land'>
      <div className='container'>
        <div className="row">
          <div className="col-lg-6 flex aic">
            <div>
              <h2 className='title-intro-hc c-white'>Marathon-Hackathon For UI/UX Designers</h2>
              <div className="s-12"></div>
              <h3 className='title-intro-sub-hc c-white w-90'>We invite Funders, CEOs, Directors, and other executives from companies in the Creative Industry to serve as members of our Jury Team.</h3>
              <div className="s-40"></div>
              <Button color='pink' text='Become Jury' round='circle' />
            </div>
          </div>
          <div className="col-lg-6 flex jcfe aic">
            <img src={pic} alt="Marathon-Hackathon" />
          </div>
        </div>
      </div>
    </div>
  )
}

export { Intro }
