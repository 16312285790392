import React from 'react'
import { Header } from '../components/part/Header'
import { Intro } from '../components/part/Intro'
import { About } from '../components/part/About'
import { Cta } from '../components/part/Cta'
import { Features } from '../components/part/Features'
import { Author } from '../components/part/Author'
import { Contest } from '../components/part/Contest'
import { Jury } from '../components/part/Jury'
import { Contact } from '../components/part/Contact'

const Landing = () => {
  return (
    <>
      <Header />
      <Intro />
      <About />
      <Features />
      <Cta />
      <Author />
      <Contest />
      <Jury />
      <Contact />
    </>
  )
}

export { Landing }
