import React from 'react'
import { Container } from 'react-bootstrap'
import icon1 from '../../assets/hackaton/icon01.png'
import icon2 from '../../assets/hackaton/icon02.png'
import icon3 from '../../assets/hackaton/icon03.png'

const Features = () => {
  return (
    <section className='flex jcc aic h-100-v'>
      <Container className='tc'>
        <h2 className="c-white title-intro-section c-pink">Key Features</h2>
        <div className="s-60"></div>
        <div className="row">
          <div className="col-lg-4">
            <img src={icon1} alt="icon" />
            <div className="s-40"></div>
            <div className="card-white">
              <p className="c-white title-intro-sub-hc w-80 ma">Write here a key feature of the app or software that is being advertised here.</p>
            </div>
          </div>
          <div className="col-lg-4">
            <img src={icon2} alt="icon" />
            <div className="s-40"></div>
            <div className="card-white">
              <p className="c-white title-intro-sub-hc w-80 ma">Write here a key feature of the app or software that is being advertised here.</p>
            </div>
          </div>
          <div className="col-lg-4">
            <img src={icon3} alt="icon" />
            <div className="s-40"></div>
            <div className="card-white">
              <p className="c-white title-intro-sub-hc w-80 ma">Write here a key feature of the app or software that is being advertised here.</p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}

export { Features }
