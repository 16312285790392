import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Landing } from './pages/Landing'
import { Privacy } from './pages/Privacy'
import { Terms } from './pages/Terms'
import { Cookie } from './pages/Cookie'
import { Error } from './pages/Error'
import { Footer } from './components/parts/Footer'

import './styles/custom.sass'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Landing />} />
        <Route path='cookie-policy' element={<Cookie />} />
        <Route path='privacy-policy' element={<Privacy />} />
        <Route path='terms-of-use' element={<Terms />} />
        <Route path="*" element={ <Error /> } />
      </Routes>
      <Footer />
    </BrowserRouter>
  )
}

export { App }
