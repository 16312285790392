import React from 'react'
import { Container } from 'react-bootstrap'

const Footer = () => {

  const year = new Date()

  return (
    <footer className='bg-light'>
      <Container>
        <div className="s-80"></div>
        <div className='flex jcsb aic px-2'>
          <p className="c-grey my-0 py-0">Copyright © {year.getFullYear()} CraftFlow Inc. All rights reserved.</p>
          <div className="menu-footer">
            <a href="/privacy-policy" className="c-grey">Privacy Policy</a>
            <span className="px-3 c-grey">|</span>
            <a href="/terms-of-use" className="c-grey">Terms of Use</a>
            <span className="px-3 c-grey">|</span>
            <a href="/cookie-policy" className="c-grey">Cookie Policy</a>
          </div>
        </div>
        <div className="s-80"></div>
      </Container>
    </footer>
  )
}

export { Footer }
