import React from 'react'
import { Container } from 'react-bootstrap'
import { Button } from '../elements/Button'
import photo from '../../assets/images/Andrey.png'


const Author = () => {
  return (
    <section className='flex jcc aic h-100-v'>
      <Container className='tc'>
        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-4 flex jcc aic">
            <img className='rounded' src={photo} alt='The new home for your design aspirations' width={'auto'} height={'auto'} />
          </div>
          <div className="col-lg-6 flex jcfs aic">
            <div className='rounded bg-white w-60 ma p-5'>
              <h3 className='c-pink'>Wellness Tips</h3>
              <div className="s-10"></div>
              <p className='title-intro-sub-hc c-dark'>Talk about this product's most important feature or service. Highlighting the most popular one works, too! Talk about this product's most important feature or service. Highlighting the most popular one works, too!</p>
              <div className="s-10"></div>
              <Button color='pink' text='Learn More' round='circle' />
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}

export { Author }
