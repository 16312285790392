import React from 'react'
import { Container } from 'react-bootstrap'

const About = () => {
  return (
    <section id='aboutLand' className='tc land flex jcc aic h-100-v'>
      <Container>
        <h2 className="c-white title-intro-section c-pink">About Marathon</h2>
        <div className="s-40"></div>
        <p className="c-white title-intro-sub w-80 ma">What's special about your product, service, or company? Use this space to highlight the things that set you apart from your competition, whether it's a special feature, a unique philosophy, or awards and recognition that you have received. Think of this as your elevator pitch to get the reader's attention.</p>
      </Container>
    </section>
  )
}

export { About }
